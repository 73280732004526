import React from 'react'
import AboutSection from "../../Home/AboutSection/AboutSection"
import GoToTop from '../../GoToTop/GoToTop'


const AboutPage = () => {
  return (
    <>
     <div className='about-section'>
        <AboutSection/>
      
    </div>
    <GoToTop/>
    </>
   
  )
}

export default AboutPage
