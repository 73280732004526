import React from "react";
import ServiceSection from "../../Home/ServiceSection/ServiceSection";
import GoToTop from "../../GoToTop/GoToTop";

const ServicePage = () => {
  return (
    <>
      <div className="service-page">
        <ServiceSection />
      </div>
      <GoToTop />
    </>
  );
};

export default ServicePage;
